
<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid v-if="ventana == 1">
      <CRow class="justify-content-center">
        <CCol class="col-10">
            <CCardGroup>
             <CCard class="mx-4 mb-0">
                <CCardBody class="p-4">
                <CForm validate @submit="generarCodigosActivacion">
                    <h1>Registrate</h1>
                    <p class="text-muted">Crea tu cuenta para SMS y Maling</p>
                     <template >
                        <CAlert
                          :show.sync="dismissCountDown"
                          closeButton
                          color="danger"
                          fade
                        >
                          <span v-html="mensajeError"></span>
                          <strong>Borrando Mensaje en {{dismissCountDown}}</strong> segundos...
                        </CAlert>
                     </template>
                    <CInput
                    placeholder="Nombre"
                    autocomplete="nombre"
                    minlength="3"
                    required
                    was-validated
                    v-model="form.name"
                    >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                    </CInput>
                    <CInput
                    placeholder="Empresa"
                    autocomplete="empresa"
                    minlength="3"
                    required
                    was-validated
                    v-model="form.empresa"
                    >
                    <template #prepend-content><CIcon name="cil-building"/></template>
                    </CInput>
                    <CInput
                    placeholder="Email"
                    autocomplete="email"
                    type="email"
                    prepend="@"
                    required
                    was-validated
                    v-model="form.email"
                    />
                    <CSelect
                        placeholder="Pais"
                        :options="[{value:'57',label:'Colombia (57)'}]"
                        required
                        was-validated
                        :value.sync="form.pais"
                        
                    >
                    <template #prepend-content><CIcon name="cil-globe-alt"/></template>
                    </CSelect>
                    <CInput
                    placeholder="Celular (Sin Indicativo del Pais)"
                    autocomplete="celular"
                    @keypress="isNumber($event)"
                    minlength="10"
                    maxlength="12"
                    required
                    was-validated
                    v-model="form.celular"
                    >
                    <template #prepend-content><CIcon name="cil-phone"/></template>
                    </CInput>
                    <CSelect
                        required
                        was-validated
                        :value.sync="form.servicio"
                        placeholder="Servicio Interesado a Probar"
                        :options="[{value:'SMS',label:'Mensajes de texto masivo (SMS)'}]"
                    >
                    <template #prepend-content><CIcon name="cil-stream"/></template>
                    </CSelect>
                     <CRow class="justify-content-center">
                        <CCol class="col-1">
                          <CSwitch
                            class="mr-1"
                            color="dark"
                            shape="pill"
                            variant="opposite"
                            :checked.sync="form.condiciones"
                          />
                        </CCol>
                        <CCol class="col-11">
                            <p> He leído los <a target="_blank" href="http://sistemasmasivos.com/envio/admin/alta/acuerdomailing.html">Términos de Servicio y políticas de confidencialidad</a> de Sistema Envio Masivo y estoy de acuerdo</p>
                        </CCol>
                     </CRow>          
                    <hr>
                    <CButton color="success" type="submit" block :disabled='isDisabled'>Crear Cuenta</CButton>
                </CForm>
                </CCardBody>
            </CCard>
            <CCard
              color="dark"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h3>Crea Tu Cuenta Gratis Ahora</h3>
                <!--<div v-if="$can('edit posts')">You can edit posts.</div>-->
                <p class="text-justify">Contacte a todos sus clientes y proveedores en segundos usando nuestros servicios de envío de mensajes de texto masivo y correo masivo 
                  Comunicación masiva de alta efectividad y bajo costo.</p>
                  <hr>
                <p class="text-justify">
                    Por favor completa el formulario y a continuación te enviaremos un email de bienvenida con tu nombre de usuario y contrasena inmediatamente. <br>
                </p>
                <router-link to="/envio/login"> <CButton color="light" variant="outline" size="lg"> Iniciar Sesion </CButton> </router-link>
              </CCardBody>
            </CCard>
            </CCardGroup>
         
        </CCol>
      </CRow>
    </CContainer>
    <CContainer fluid v-if="ventana == 2">
      <CRow class="justify-content-center">
        <CCol class="col-10">
            <CCardGroup>
             <CCard class="mx-4 mb-0">
                <CCardBody class="p-4">
                <CForm validate @submit="enviarCodigos">
                    <h3>Registro de Nuevo Usuario</h3>
                    <br>
                    <template v-if="errorCodigos.length">
                      <h1>Tienes Codigo de Email No Validado</h1>
                    </template>
                    <CInput
                    placeholder="Codigo Enviado Por Correo"
                    autocomplete="off"
                    required
                    was-validated
                    minlength="3"
                    maxlength="6"
                    v-model="form_codigos.codigo_email"
                    >
                    <template #prepend-content><CIcon name="cil-at"/></template>
                    </CInput>
                    <CInput
                    placeholder="Codigo Enviado Por Celular"
                    autocomplete="off"
                    required
                    was-validated
                    minlength="3"
                    maxlength="6"
                    v-model="form_codigos.codigo_celular"
                    >
                    <template #prepend-content><CIcon name="cil-phone"/></template>
                    </CInput>
                    <hr>
                    <CButton color="success" type="submit" block :disabled='isDisabled'>Crear Cuenta</CButton>

                    <hr>
                    <h6>Si escribiste incorrectamente la direccion de correo o numero celular, haz <a @click="modificarFormulario" class="text-primary" >Click Aqui</a> y registrate nuevamente</h6>
                </CForm>
                </CCardBody>
            </CCard>
            <CCard
              color="info"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h5 class="text-justify">Hemos enviado un mensaje con los codigos de activacion a tu correo y numero de telefono</h5>
                <p class="text-justify mb-0"><strong>Correo:</strong> {{this.form.email}}</p>
                <p class="text-justify"><strong>Numero Celular:</strong> {{this.form.pais}}{{this.form.celular}}</p>
                <h6 class="text-justify">Ingresalos a continuacion para activar tu cuenta (recomendamos que lo copie y lo pegue en el caso del correo).</h6>
                
              </CCardBody>
            </CCard>
            </CCardGroup>
         
        </CCol>
      </CRow>
    </CContainer>
    <CContainer fluid v-if="ventana == 3">
      <CRow class="justify-content-center">
        <CCol class="col-10">
            <CCardGroup>
             <CCard class="mx-4 mb-0">
                <CCardBody class="p-4">
                    <h1>Registrate</h1>
                    <p class="text-muted">Crea tu Cuenta de SMS Ahora</p>
                     <br>
                     <p>No es Posible Crear Una Cuenta en este Momento Puede Crearla desde Las 7:00 am a 7:00 pm Hora Colombia</p>
                </CCardBody>
            </CCard>
            <CCard
              color="dark"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h3>Crea Tu Cuenta de SMS Gratis Ahora</h3>
                <!--<div v-if="$can('edit posts')">You can edit posts.</div>-->
                <p class="text-justify">SMS MASIVO
                  Contacte a todos sus clientes y proveedores en segundos usando nuestros servicios de envío de mensajes de texto masivo, <br>
                  SMS con link a Whatsapp y SMS multimedia. Comunicación masiva de alta efectividad y bajo costo.</p>
                  <hr>
                <p class="text-justify">
                    Por favor completa el formulario y a continuación te enviaremos un email de bienvenida con tu nombre de usuario y contrasena inmediatamente. <br>
                </p>
                <router-link to="/envio/login"> <CButton color="light" variant="outline" size="lg"> Iniciar Sesion </CButton> </router-link>
              </CCardBody>
            </CCard>
            </CCardGroup>
         
        </CCol>
      </CRow>
    </CContainer>
    <div :style="'width: 100%;height: 100%;top: 0;left: 0;position: fixed;display: flex;opacity: 0.3;background-color: #fff;z-index: 10000;text-align: center;justify-content: center;align-items: center;visibility:'+visibleLoading">
            <CSpinner
              style="width:8rem;height:8rem;"
              color="info"
              grow
            />
          </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrarCuentaSms',
  data(){
    return{
       errors: [],
       errorCodigos:[],
       ventana: 1,
       form: {
         name:'',
         empresa:'',
         email:'',
         pais:'',
         celular:'',
         servicio:'',
         condiciones:false
       },
       form_codigos: {
         codigo_email:'',
         codigo_celular:'',
       },
       mensajeError:'',
       dismissCountDown:0,
    }
  },
  computed: {
  	isDisabled: function(){
    	return !this.form.condiciones;
    },
    visibleLoading(){
      return this.$store.state.auth.loading_http;
    }
  },
  methods:{
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    generarCodigosActivacion: function (e) {
      e.preventDefault();
      //console.log(this.form);
       //this.ventana = 2
       this.$store.dispatch('crearcuentasms/guardarDataFormularioRegistrar',this.form);
       this.$store.dispatch('auth/loading_http','visible');
       this.$http.post('/sms/activarcuenta/registrar',{
         email:this.form.email,
         celular:this.form.pais+this.form.celular,
         name:this.form.name,
       }).then(response =>{
          if (response.data.status == "OK") {
              this.ventana = 2
          }else{
             let email = response.data.email;
             let celular = response.data.celular;
             let mensajeError = '';
             if (email != undefined) {
                  mensajeError += `* ${email} <br>`;
             }
             if (celular != undefined) {
                  mensajeError += `* ${celular} <br>`;
             }
             if (mensajeError == '') {
                mensajeError = 'Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>';
             }
             
             this.mensajeError = mensajeError;
             this.dismissCountDown = 60;
          }
       }).catch(error =>{
            let mensajeError = '';
            if (error.response) {
              let dataError = error.response.data;
               if (error.response.status == 422) {
                   mensajeError = `${dataError.message} \n`;
                   let errors = dataError.errors;
                   for (const key in errors) {
                     if (Object.hasOwnProperty.call(errors, key)) {
                       const element = errors[key];
                       for (const iterator of element) {
                          mensajeError += `${iterator} \n`
                       }
                     }
                   }
                   this.mensajeError = mensajeError;
                   this.dismissCountDown = 60;
               }else{
                  this.mensajeError = 'Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>';;
                  this.dismissCountDown = 60;
               }
            }
       }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    modificarFormulario (e){
      this.ventana = 1;
      this.form = this.$store.state.crearcuentasms.formularioRegistrar;
      e.preventDefault();
    },
    enviarCodigos(e){
      e.preventDefault();
      let validahora = this.validarFueraDeHorario();
       if (validahora == "NO") {
         return false;
       }
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post('/sms/activarcuenta/validacion',{
        email:this.form.email,
        codigo:this.form_codigos.codigo_email,
        celular:this.form.pais+this.form.celular,
        codigo_celular:this.form_codigos.codigo_celular,
      }).then(response => {
         if (response.data.status == "OK") {
            this.crearcuentasms();
         }else if(response.data.status == "ERROR"){
            let mensajeError = '';
            if (response.data.mensaje_codigo == "ERROR") {
              mensajeError += `* El Codigo Enviado Por Email Es Incorrecto <br>`;
            }
            if (response.data.mensaje_codigo_celular == "ERROR") {
              mensajeError += `* El Codigo Enviado Por El Celular Es Incorrecto <br>`;
            }
            if (mensajeError == '') {
                mensajeError = 'Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>';
            }
            this.mensajeError = mensajeError;
            this.dismissCountDown = 60;
         }
      }).catch(() => {
         this.mensajeError = 'Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>';;
         this.dismissCountDown = 60;
      }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
      //this.$router.push({ path: 'login'})
    },
    crearcuentasms(){
      //this.$store.dispatch('crearcuentasms/guardarMensajeRegistrar',true);
      //this.$router.push({ path: 'login'})
       let validahora = this.validarFueraDeHorario();
       if (validahora == "NO") {
         return false;
       }
       this.$store.dispatch('auth/loading_http','visible');
       this.$http.post('/sms/crearcuenta',{
         name: this.form.name,
         empresa:this.form.empresa,
         email:this.form.email,
         pais:this.form.pais,
         celular:this.form.pais+this.form.celular,
         servicio:this.form.servicio,
         condiciones:this.form.condiciones,
      }).then(response => {
         if (response.data.status == "OK") {
           //this.$store.dispatch('crearcuentasms/guardarMensajeRegistrar',true);
           localStorage.setItem('cuenta_creada','OK');
           this.$router.push({ name: 'envio_login'})
         }else if(response.data.status == "ERROR"){
           this.mensajeError = 'Ocurrio Un Error En Nuestra Plataforma En Unos Minutos Se Enviaran Las Credenciales a Su Correo <br>';
           this.dismissCountDown = 60;
         }
      }).catch(error =>{
            let mensajeError = '';
            if (error.response) {
              let dataError = error.response.data;
               if (error.response.status == 422) {
                   mensajeError = `${dataError.message} \n`;
                   let errors = dataError.errors;
                   for (const key in errors) {
                     if (Object.hasOwnProperty.call(errors, key)) {
                       const element = errors[key];
                       for (const iterator of element) {
                          mensajeError += `${iterator} \n`
                       }
                     }
                   }
                   this.mensajeError = mensajeError;
                   this.dismissCountDown = 60;
               }else{
                  this.mensajeError = 'Ocurrio Un Error En Nuestra Plataforma Por Favor Intentar Mas Tarde <br>';;
                  this.dismissCountDown = 60;
               }
            }
       }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    calcTime(city, offset) {
      // creamos el objeto Date (la selecciona de la máquina cliente)
      let d = new Date();
      // lo convierte  a milisegundos
      // añade la dirferencia horaria
      // recupera la hora en formato UTC
      let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      // crea un nuevo objeto Date usando la diferencia dada.
      let nd = new Date(utc + (3600000*offset));
      try {
        let hora = (nd.toTimeString()).substr(0,8);
        let fecha = (nd.toLocaleString()).split(" ")[0];
        fecha = fecha.replace(',','');
        let tipo = 'a. m.'
        //console.log(fecha);
        if (parseInt(hora.split(':')[0]) >= 12 && parseInt(hora.split(':')[0]) <= 23){
            tipo = 'p. m.'
        }
        let nueva_fecha = `${fecha}, ${hora} ${tipo}`;
        //console.log(nd.toLocaleString());
        return nueva_fecha;
      } catch (error) {
        return nd.toLocaleString();
      }
    },
    validarFueraDeHorario(){
       let fechaColombia = this.calcTime('Bogota', '-5');
       //console.log(fechaColombia);
       //let fechaActual = new Date().toLocaleString();
       //let horaActual = fechaActual.split(" ")[1];
       let horaColombia = fechaColombia.split(" ")[1];
       //let tiempoActual = fechaActual.split(" ")[2];
       let tiempoColombia = fechaColombia.split(" ")[2];
       horaColombia = horaColombia.trim();
       //horaColombia = '3:59:59'
       //tiempoColombia = 'p. m.'
       horaColombia = this.convertirHora24X12(horaColombia);
       //console.log(horaColombia);
       //let calcula = horaColombia.split(":")[0];
       //console.log(calcula);
       //horaColombia = calcula >= 1 && calcula <= 9 ? '0'+horaColombia : horaColombia;
       //console.log(horaColombia);
       //horaColombia = '14:10:59'
       //tiempoColombia = 'a. m.'
       horaColombia = horaColombia.substr(0,5);
       //console.log(horaColombia);
       //console.log(tiempoColombia);
       if ((horaColombia >= '07:00' &&  horaColombia <= '11:59' && tiempoColombia == 'a. m.') || (horaColombia >= '12:00' && horaColombia <= '12:59' && tiempoColombia == 'p. m.') || (horaColombia >= '01:00' && horaColombia <= '06:59' && tiempoColombia == 'p. m.')) {
         this.ventana = 1;
         return "OK";
       }else{
         this.ventana = 1;//this.ventana = 3;
         return "OK";
       }
       
    },
    convertirHora24X12(hora){
       let calcula = hora.split(":")[0];
       if (calcula >= 13 && calcula <= 23) {
            calcula = calcula - 12;
            let horamod = hora.split(":");
            horamod[0] = calcula;
            hora = horamod.join(":");
       }

       return hora;
    }
  },
  mounted () {
    setTimeout(() => {
     this.validarFueraDeHorario();
    },100)
  }
}
</script>

